(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.public.resultats')
    .controller('resultatsCtrl2', resultatsCtrl2);

  function resultatsCtrl2() {
   var vm = this;
   
   
 
  }
}());


/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


